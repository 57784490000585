<template>
  <div class="sidebar-container">
    <div class="sidebar">
      <ul>
        <li><a @click="$emit('all')">All</a></li>
        <li><a @click="$emit('armours')">Equipment</a></li>
        <li><a @click="$emit('weapons')">Weapon</a></li>
        <li><a @click="$emit('potions')">Potion</a></li>
      </ul>
      <!-- <ul class="item-class">
        <li><a class="fabled">Fabled</a></li>
        <li><a class="legendary">Legendary</a></li>
        <li><a class="epic">Epic</a></li>
        <li><a class="artefact">Artefact</a></li>
        <li><a class="mythical">Mythical</a></li>
        <li><a class="rare">Rare</a></li>
        <li><a class="shoddy">Shoddy</a></li>
        <li><a class="uncommon">Uncommon</a></li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  displayName: "Sidebar",
  emits: ["all", "armours", "weapons", "potions", "resources"],
  props: {
    size: {
      // small || extra-small || default large
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.sidebar-container {
  position: relative;
  .sidebar {
    background-color: #0f1214;
    width: 100%;
    height: 100%;
    position: sticky;
    top: 20px;
    border-radius: 10px;
    ul {
      list-style: none;
      margin: 0;
      padding: 10px;
      li {
        font-size: 1.1rem;
        text-align: left;
        margin: 0 0 10px;
        border-radius: 10px;
        overflow: hidden;
        a {
          position: relative;
          text-decoration: none;
          background-color: #131518;
          display: block;
          padding: 1rem;
          text-transform: capitalize;
          border-radius: 10px;
          color: #fff;
          overflow: hidden;
        }
      }
      &.item-class {
        li {
          position: relative;
          a {
            background: rgba(0, 0, 0, 0.8);
            &::after {
              content: "";
              width: 30px;
              display: block;
              position: absolute;
              height: 30px;
              top: 50%;
              right: 10px;
              z-index: 100;
              border-radius: 100%;
              display: block;
              transform: translateY(-50%);
            }
            &.shoddy {
              &::after {
                border: 1px solid $shoddy;
              }
            }

            &.epic {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($epic, 50%),
                  $epic,
                  darken($epic, 20%),
                  $epic
                );
              }
            }

            &.legendary {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($legendary, 20%),
                  $legendary,
                  darken($legendary, 20%),
                  $legendary
                );
              }
            }

            &.mythical {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($mythical, 20%),
                  $mythical,
                  darken($mythical, 20%),
                  $mythical
                );
              }
            }

            &.fabled {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($fabled, 20%),
                  $fabled,
                  darken($fabled, 20%),
                  $fabled
                );
              }
            }

            &.unique {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($unique, 20%),
                  $unique,
                  darken($unique, 20%),
                  $unique
                );
              }
            }

            &.rare {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($rare, 30%),
                  $rare,
                  darken($rare, 30%),
                  $rare
                );
              }
            }

            &.artefact {
              &::after {
                background-image: linear-gradient(
                  60deg,
                  lighten($artefact, 50%),
                  $artefact,
                  darken($artefact, 30%),
                  $artefact
                );
              }
            }

            &.junk {
              &:after {
                border: 1px solid #000;
              }
            }

            &.uncommon {
              &:after {
                border: 1px solid $uncommon;
              }
            }
          }
        }
        &.background {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
</style>
