<template>
  <div :class="['mini-popup', type]">
    <span v-if="type !== 'single'" class="name">
      {{ item.name }}
    </span>
    <div v-if="type !== 'single'" class="rarity">
      <span :class="[item.rarity.toLowerCase()]">
        {{ item.rarity }}
      </span>
    </div>
    <div class="meta quantity" v-if="item.quantity">
      <span class="key">Quantity</span
      ><span class="value">{{ item.quantity }}</span>
    </div>
    <div class="meta weapon" v-if="item.weaponType">
      <span class="key">Type</span>
      <span class="value">{{ item.weaponType }}</span>
    </div>
    <div class="meta attack" v-if="item.attack">
      <span class="key">Attack</span>
      <span class="value">{{ item.attack }}</span>
    </div>
    <div class="meta defense" v-if="item.defense">
      <span class="key">Defense</span>
      <span class="value">{{ item.defense }}</span>
    </div>
    <div class="meta magic" v-if="item.magic">
      <span class="key">Magic</span>
      <span class="value">{{ item.magic }}</span>
    </div>
    <div class="meta hp-bonus" v-if="item.totalHp">
      <span class="key">HP</span>
      <span class="value">{{ item.totalHp }}</span>
    </div>
    <div class="meta durability" v-if="item.durability">
      <span class="key">Durability</span>
      <span class="value"
        >{{ item.durability }}/{{ item.totalDurability }}</span
      >
    </div>
    <div class="meta crafting" v-if="item.craftingRequired">
      <span class="key">Crafting</span>
      <span class="value">{{ item.craftingRequired }}</span>
    </div>
    <div class="meta broken" v-if="item.isBroken !== undefined">
      <span class="key">Broken</span>
      <span class="value">{{ item.isBroken }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupPopper",
  props: {
    item: {},
    currentCategory: {
      type: String,
    },
    type: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.mini-popup {
  background-image: url("https://cdn.dragoncrypto.io/popup/popup-base.webp");
  background-position: center center;
  background-repeat: repeat;
  border: 10px solid;
  border-image-source: url("../../assets/popup/small-border.webp");
  border-image-outset: 0;
  border-image-slice: 53;
  border-image-repeat: repeat;
  background-color: #171110;
  display: block;
  min-width: 200px;
  padding: 5px;
  &.single {
    background-image: none;
    border-image-source: none;
    border: none;
    padding: 1rem;
    border-radius: 10px;
    background-color: #111416;
  }
  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    &.rarity {
      margin-bottom: 10px;
      text-align: center;
      display: block;
      span {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &.meta {
      span {
        font-family: "Lato", sans-serif;
        background-color: #00000049;
        padding: 5px 10px;
        font-size: 1rem;
        &.value {
          text-align: center;
          background-color: #5b5b5b49;
          text-transform: capitalize;
        }
      }
    }
  }
  span {
    display: block;
    font-family: "IM Fell English", serif;
    font-size: 1.1rem;
    margin-bottom: 2px;
    &.name {
      text-align: center;
      margin: 0 auto 10px;
    }
    &.shoddy {
      &::after {
        border: 1px solid $shoddy;
      }
    }

    &.epic {
      background-color: $epic;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.legendary {
      background-color: $legendary;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.mythical {
      background-color: $mythical;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.fabled {
      background-color: $fabled;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.unique {
      background-color: $unique;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.rare {
      background-color: $rare;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.artefact {
      background-color: $artefact;
      display: inline-block;
      padding: 2px 10px;
      color: #000;
    }

    &.junk {
      &:after {
        border: 1px solid #000;
      }
    }

    &.uncommon {
      &:after {
        border: 1px solid $uncommon;
      }
    }
  }
}
</style>
