<template>
  <div class="market-item">
    <div class="product-info">
      <SkeletalLoading class="image-loading" width="200" height="200" />
      <div class="details">
        <SkeletalLoading class="title" width="100" height="20" />
        <div class="price-details">
          <SkeletalLoading class="price" width="50" height="20" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";

export default {
  name: "ItemLoading",

  props: {
    item: {},
    isOwner: { type: Boolean, default: false },
  },
  components: {
    SkeletalLoading,
  },
};
</script>
<style lang="scss">
span.skeleton-box {
  opacity: 0.1;
  &.image-loading {
    width: 100% !important;
    opacity: 0.05;
  }
  &.title {
    width: 100% !important;
    margin: 5px 0 3px !important;
  }
  &.price {
    margin: 2px 0 !important;
  }
}
.market-item {
  &:hover {
    .item-image {
      transform: scale(1.1);
    }
  }
}
.button-container {
  .dialog-button {
    margin-top: 0 !important;
  }
}
</style>

<style scoped lang="scss">
@import "../../assets/scss/globals.scss";
:deep(.popper #arrow) {
  z-index: -1;
}
:deep(.popper #arrow::before) {
  &:hover {
    background: #969292 !important;
  }
  background: #969292;
}
.market-item {
  padding: 10px;
  background: #171110;
  border-radius: 5px;
  /* min-width: 200px; */
  position: relative;
  &:hover {
    /* background: #251c1a; */
    img {
      transform: scale(1.2);
    }
    .button-container {
      display: block;
    }
  }

  .product-info {
    align-items: start;
  }

  .product-meta {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 0.8rem;
    color: #ffffff59;
    padding: 5px 0 0;
    border-top: 1px solid #ffffff17;
    margin-top: 5px;
    justify-items: baseline;
    .address {
      text-align: left;
      &:before {
        background: url("https://cdn.dragoncrypto.io/uiassets/user.svg")
          no-repeat left center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        opacity: 0.4;
        height: 15px;
        width: 15px;
        display: inline-block;
        margin-top: -3px;
        top: 3px;
        position: relative;
      }
    }
    .listed-time {
      text-align: right;
      &:before {
        background: url("https://cdn.dragoncrypto.io/uiassets/clock.svg")
          no-repeat left center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        opacity: 0.4;
        height: 14px;
        width: 14px;
        display: inline-block;
        margin-top: -3px;
        top: 3px;
        position: relative;
      }
    }
  }

  .details {
    justify-items: left;
    position: relative;
    min-height: 60px;
    .price-details {
      text-align: left;
      display: grid;
      grid-template-columns: 1fr 20px;
      .info {
        border: 1px #ffffff solid;
        display: grid;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        opacity: 0.3;
        &:hover {
          cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
            auto;
        }
      }
    }
    span.price {
      font-size: 1rem;
      font-weight: bold;
      display: inline-block;
      text-align: left;
      margin-left: 0;
      span {
        opacity: 0.5;
      }
      &:before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-right: 4px;
      }
      &.DCAR:before {
        background: url("../../assets/ui/dcar.png") no-repeat left center;
        background-size: contain;
      }
      &.DCAU:before {
        background: url("../../assets/ui/dcau.png") no-repeat left center;
        background-size: contain;
      }
    }
    span.dollar-value {
      font-size: 0.65rem;
      display: inline-block;
      margin: 2px 0 0 5px;
      color: rgba(255, 255, 255, 0.5215686275);
    }
    span.skeleton-box {
      display: inline-block !important;
      top: 3px;
      position: relative;
      margin-left: 5px;
      opacity: 0.2;
    }
    .dialog-button {
      @media only screen and (max-width: 576px) {
        transform: scale(0.8);
      }
    }
    h3 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      margin: 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.rare {
        color: $rare;
      }

      &.epic {
        color: $epic;
      }

      &.mythical {
        color: $mythical;
      }

      &.legendary {
        color: $legendary;
      }

      &.fabled {
        color: $fabled;
      }

      &.unique {
        color: $unique;
      }

      &.artefact {
        color: $artefact;
      }

      &.uncommon {
        color: $uncommon;
      }
    }
  }
  .button-container {
    position: absolute;
    width: 100%;
    display: none;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.skeleton-box {
  position: relative;
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-gap: 1rem;
  .square,
  .rectangle {
    display: block;
    position: relative;
    overflow: hidden;
    background: #464646;
    animation: skeleton 1s ease-in-out forwards infinite;
    animation-direction: alternate;
  }
  .details {
    display: grid;
    align-items: center;
  }

  /* .loading::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      animation: 2s shimmer linear 0.5s infinite;
      background: linear-gradient(90deg, transparent, #ededed, transparent);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      transform: translateX(-100%);
      z-index: 1;
    } */

  @keyframes skeleton {
    0% {
      opacity: 0.3;
      transform: translateY(3px) scale(0.98);
    }
    100% {
      opacity: 0.6;
      transform: translateY(0px) scale(1);
    }
  }
}
</style>
